import { Configuration, FrontendApi } from "@ory/client";
import { KRATOS_API_URL } from "../../../config/envConstants";

const newLocal = new FrontendApi(
  new Configuration({
    basePath: KRATOS_API_URL,
    baseOptions: {
      withCredentials: true,
    },
  }),
);
export const ory = newLocal;
