import { AxiosError } from "axios";
import { isWindowUndefined } from "../../utils/isWindowUndefined";
import { ory } from "./sdk/client";
import { showErrorToast } from "../../utils/errorsToast";
import { ErrorReporter } from "../ErrorReporter";

export function logout(redirect = true) {
  ory
    .createBrowserLogoutFlow()
    .then(async ({ data }) => {
      if (data.logout_token) {
        await ory.updateLogoutFlow({ token: data.logout_token });
        if (!isWindowUndefined(window) && redirect) {
          window.location.href = "/auth/signin";
        }
      }
    })
    .catch((err: AxiosError) => {
      switch (err.response?.status) {
        case 401:
          // do nothing, the user is not logged in
          if (redirect) {
            window.location.href = "/auth/signin";
          }
          return;
      }
      if (redirect) {
        ErrorReporter.collect(err, "error while logout");

        showErrorToast(err);
      }
      // Something else happened!
      // return Promise.reject(err);
    });
}
