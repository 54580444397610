import { Session } from "@ory/client";

export const isSessionPrivileged = (
  authenticatedAt,
  privilegeSessionAge = "30m",
) => {
  const forceSudoModeAge = localStorage?.getItem("mecha.forceSudoModeAge");
  if (forceSudoModeAge) {
    privilegeSessionAge = forceSudoModeAge;
  }

  const formattedTimestamp = new Date(authenticatedAt);
  const authenticatedAtUTC = new Date(
    formattedTimestamp.toISOString().replace(/Z$/, "+00:00"),
  );

  const ageAdded = new Date(
    authenticatedAtUTC.getTime() +
      parseInt(privilegeSessionAge.replace("m", "")) * 60000,
  );

  const nowUTC = new Date();
  const nowUTCString = nowUTC.toISOString().replace(/Z$/, "+00:00");
  const nowUTCDate = new Date(nowUTCString);

  return ageAdded.getTime() > nowUTCDate.getTime();
};

export const isSessionValid = (session: Session) => {
  return session && isSessionActive(session) && isEmailVerified(session);
};

export const isEmailVerified = (session: Session) => {
  return session.identity.verifiable_addresses[0].verified;
};

export const isSessionActive = (session: Session) => {
  const inValidMethod = session.authentication_methods.filter(a =>
    ["code_recovery", "link_recovery"].includes(a.method),
  );

  return inValidMethod.length === 0 && session.active && session.identity;
};
